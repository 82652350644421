import clsx from 'clsx';
import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';
import { TEXT_COLOR_MAP } from '../../../../constants/theme.constants';

interface Props {
  className?: string;
}

interface CoreBeliefs {
  beliefText: string;
  textColor: string;
}

const CLPHQVision = (props: Props) => {
  const { className } = props;
  const {
    hqVision: { CoreBeliefs, supportingStatement },
  } = useStrapiCLPData();

  const textContent = CoreBeliefs.map((text: CoreBeliefs, index: number) => (
    <span key={index} className={TEXT_COLOR_MAP[text.textColor]}>
      {text.beliefText}
      {index < CoreBeliefs.length - 1 && ' '}
    </span>
  ));

  return (
    <section className={clsx(className)}>
      <div className="flex flex-col items-center gap-4 text-left text-base font-medium text-base-soft-black sm:gap-6 sm:text-center sm:text-lg md:text-xl lg:text-2xl">
        <p>{textContent}</p>
        <p className="inline">
          {supportingStatement}
          {/* <Link to="/about" className="underline">
            {actionPrompt}
          </Link> */}
        </p>
      </div>
    </section>
  );
};

export default CLPHQVision;
